import * as React from "react"

import styled from "styled-components"

import Background from "../images/signin/background_sign_in.svg"
// import "../css/signin-style.css"

let awhite = "#fcfdfc"
let ForestGreen = "#2f8132"

let Orchid = "#ad4bb8"
let Gray_90 = "#423d33"
let Gray_70 = "#625d52"
let Gray_50 = "#a39e93"
let Gray_30 = "hsl(40, 15%, 80%, 1)"
let Gray_10 = "#faf9f7"

const AnnualStyle = styled.section`
  body {
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 50%;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
  }
  .container {
    color: #423d33;
    background-color: ${Gray_10};
    padding: 24px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0px 24px 32px rgba(66, 61, 51, 0.13);
    transition: width 0.3s ease-in;
    transition: height 0.3s ease-in;
    width: 352px;
  }
  h1,
  h2,
  small {
    margin: 0;
    padding: 0;
  }

  small {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: ${Gray_70};
  }
  small a {
    text-decoration: underline;
    color: ${Gray_90};
  }
  small a:hover {
    color: #57ae5b;
    transition: color 0.3s ease-in;
  }
  h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
  }

  .container input {
    padding: 0;
    background-color: ${awhite};
    border: 1px solid ${Gray_30};
    color: ${Gray_50};
    transition: border-color 0.4s ease, color 0.4s ease;
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    width: 224px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    outline: none;
  }

  .container input:hover {
    color: ${Gray_50};
    border: 1px solid ${Gray_50};
    transition: border-color 0.4s ease, color 0.4s ease;
  }
  .container input:focus {
    color: ${Gray_90};
    border: 1px solid ${Gray_90};
    transition: border-color 0.4s ease, color 0.4s ease;
  }
  .container input:valid {
    background-color: ${awhite};
    color: ${ForestGreen};
    transition: border-color 0.4s ease, color 0.4s ease;
  }
  .container input:invalid {
    background-color: ${awhite};
    color: ${Orchid};
    border: 1px solid ${Gray_90};
    transition: border-color 0.4s ease, color 0.4s ease;
  }

  .container.alert {
    background: linear-gradient(180deg, #f5e1f7 0%, #faf9f7 54.69%);
    color: ${Orchid};
  }
  .btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px 32px;
    width: fit-content;
    border: none;
    background: ${ForestGreen};
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
    border-radius: 4px;
    font-size: 14px;
    color: ${awhite};
    transform: scale(1);
    outline: none;
  }
  .btn:hover {
    background: #57ae5b;
    transform: scale(0.95);
    transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
  }
  .btn:focus {
    outline: none;
    outline: 2px solid;
  }
  .btn:active {
    transform: scale(0.9);
    transition: none;
    box-shadow: inset 0px 2px 2px rgba(14, 88, 20, 0.25);
    outline: none;
  }
  .btn:disabled {
    cursor: not-allowed;
    background-color: ${Gray_30};
  }
  .btn:disabled:hover {
    cursor: not-allowed;
    transform: none;
    background-color: ${Gray_30};
  }

  .fade-in {
    animation: fadeIn ease 0.5s;
    -webkit-animation: fadeIn ease 0.5s;
    -moz-animation: fadeIn ease 0.5s;
    -o-animation: fadeIn ease 0.5s;
    -ms-animation: fadeIn ease 0.5s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 426px) {
    .container {
      width: 100vw;
      height: 100vh;
      justify-content: start;
      min-width: 300px;
      border-radius: 0;
      transition: width 0.3s ease-in, height 0.3s ease-in;
    }
  }

  .background-image {
    height: 100%;
    max-height: 3840px;
    position: absolute;
    z-index: 0;
    top: 1000;
  }

  .container {
    z-index: 1;
  }
`

const Signin = () => {
  return (
    <AnnualStyle>
      <body>
        <Background className="background-image"></Background>
        <div class="container fade-in">
          <h1>Client Login</h1>
          <small>Please fill in your log in data.</small>
          <input type="email" name="email" placeholder="email" />
          <input
            type="password"
            id="password"
            name="password"
            placeholder="password"
            minlength="6"
          />
          <small class="underlined">
            <a href="">Forgot your password?</a>
          </small>
          <button class="btn">Login</button>
        </div>
      </body>
    </AnnualStyle>
  )
}

export default Signin
