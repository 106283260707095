import * as React from "react"
import styled from "styled-components"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Signin from "../components/singin"

const AnnualStyle = styled.section`
  font-family: "Roboto";
  font-style: normal;
`

const IndexPage = props => {
  return (
    <AnnualStyle>
      <Signin></Signin>
    </AnnualStyle>
  )
}

export default IndexPage
